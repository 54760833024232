import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import '../assets/css/Totd.css'; // Import the CSS file for typewriter effect

const TOTD = () => {
  const [data, setData] = useState([]);
  const [tipText, setTipText] = useState('... loading ...');
  const [totdtpText, setTotdtpText] = useState('....');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://data.rippaday.com/v1/today/R/totdperf')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    axios.get('https://data.rippaday.com/v1/today/R/totd')
      .then(response => {
        setTipText(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching tip text:', error);
      });

    axios.get('https://data.rippaday.com/v1/today/R/totdtp')
      .then(response => {
        setTotdtpText(response.data);
      })
      .catch(error => {
        console.error('Error fetching totdtp text:', error);
      });
  }, []);

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return date.toISOString().split('T')[0];
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#333', padding: '10px', color: '#fff' }}>
          <p>{`Date: ${label}`}</p>
          <p>{`Place Profit: $${data.placeProfit.toLocaleString()}`}</p>
          <p>{`Result: ${data.result}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="container">
      <section className="ctext section-body" id="header1-0" data-rv-view="0">
        <div className="filter-container">
          <p></p>
          <div id="todaystip" className="totdText">
            <h2>Tip of the day</h2>
            <p className={loading ? "typewriter" : ""}>{loading ? tipText : tipText}</p>
          </div>
          <div className="totdText">Total tip of the day performance to date</div>
          <div id="totdtp" className="totdText">
            <p>{totdtpText}</p>
          </div>
          <p></p>

          <h3 style={{ textAlign: 'center', color: '#333' }}>Rolling 30 Days Place Profit</h3>
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="date"
                tickFormatter={formatXAxis}
                angle={-45}
                textAnchor="end"
                interval={0}
                height={60}
                className="hide-mobile"
              />
              <YAxis
                dataKey="placeProfit"
                className="hide-mobile"
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="bottom" height={36} payload={[{ value: 'Units Profit', type: 'square', color: '#d1cfc7' }]} />
              <Area
                type="monotone"
                dataKey="placeProfit"
                stroke="#d1cfc7"
                fill="#d1cfc7"
                fillOpacity={0.3}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </section>
    </div>
  );
};

export default TOTD;
